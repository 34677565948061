<template>
  <div class="sso-redirect" :style="{ height: pageHeight }">
    <b-overlay :show="showOverlay" rounded="sm" opacity="0">
      <div>
        <div class="row">
          <div class="col-sm-10 offset-sm-1">
            <div class="alert alert-primary mt-5" v-if="sessionTimeout">
              <span class="icon-holder">
                <svg
                  enable-background="new 0 0 35 33.5"
                  viewBox="0 0 35 33.5"
                  xmlns="http://www.w3.org/2000/svg"
                >
                  <ellipse
                    clip-rule="evenodd"
                    cx="17.4"
                    cy="16.3"
                    fill="#2a5db0"
                    fill-rule="evenodd"
                    rx="16"
                    ry="15.4"
                  />
                  <path
                    d="m16.8 10.8c-.6 0-1.1-.2-1.5-.5s-.6-.8-.6-1.3.2-1 .6-1.3.9-.5 1.5-.5 1.1.2 1.5.5.6.8.6 1.3-.2 1-.6 1.3-.9.5-1.5.5zm-1.7 13.6v-11.9h3.5v11.9z"
                    fill="#fff"
                    transform="translate(0 .5)"
                  />
                </svg>
              </span>
              <b>
                Your Ethos&trade; Performance Intelligence session has ended.
                Please close this tab and return to Ethos.
              </b>
            </div>

            <div class="alert alert-error mt-5" v-if="ssoError">
              <div class="row">
                <div class="col-sm-12">
                  <span class="icon-holder">
                    <svg
                      width="40px"
                      height="41px"
                      viewBox="0 0 40 41"
                      version="1.1"
                      xmlns="http://www.w3.org/2000/svg"
                      xmlns:xlink="http://www.w3.org/1999/xlink"
                    >
                      <title>error_primary-color</title>
                      <defs>
                        <filter id="filter-1">
                          <feColorMatrix
                            in="SourceGraphic"
                            type="matrix"
                            values="0 0 0 0 0.901961 0 0 0 0 0.117647 0 0 0 0 0.207843 0 0 0 1.000000 0"
                          ></feColorMatrix>
                        </filter>
                      </defs>
                      <g
                        id="IC-Working-Canvas"
                        stroke="none"
                        stroke-width="1"
                        fill="none"
                        fill-rule="evenodd"
                      >
                        <g
                          id="error_primary-color"
                          transform="translate(-142.000000, -88.000000)"
                          filter="url(#filter-1)"
                        >
                          <g transform="translate(142.000000, 88.500000)">
                            <path
                              d="M2.72092624,37.1774691 L37.2785108,37.1774691 L20.089473,2.82310484 L2.72092624,37.1774691 Z M0.311277934,35.8665402 L17.6798247,1.51217602 C18.3777681,0.131668886 20.0223993,-0.400530438 21.3532113,0.323475557 C21.8462067,0.591681421 22.2480353,1.01029488 22.5044836,1.5228377 L39.6935215,35.8772019 C40.3857886,37.2607812 39.8657448,38.9645432 38.5319713,39.6826609 C38.1447212,39.8911605 37.7148149,40 37.2785108,40 L2.72092624,40 C1.21820017,40 0,38.7363099 0,37.1774691 C0,36.7208055 0.1068149,36.2709608 0.311277934,35.8665402 Z"
                              id="Path-2"
                              fill="#015CFE"
                              fill-rule="nonzero"
                            ></path>
                            <path
                              d="M18.6363636,15.9090909 C18.6363636,15.1559753 19.2468844,14.5454545 20,14.5454545 C20.7531156,14.5454545 21.3636364,15.1559753 21.3636364,15.9090909 L21.3636364,25 C21.3636364,25.7531156 20.7531156,26.3636364 20,26.3636364 C19.2468844,26.3636364 18.6363636,25.7531156 18.6363636,25 L18.6363636,15.9090909 Z"
                              id="Line"
                              fill="#015CFE"
                              fill-rule="nonzero"
                            ></path>
                            <circle
                              id="Oval-4"
                              fill="#015CFE"
                              cx="20"
                              cy="30.9090909"
                              r="1.81818182"
                            ></circle>
                          </g>
                        </g>
                      </g>
                    </svg>
                  </span>
                  <b class="error-msg px-3">Unexpected System Error.</b>
                  <div class="d-block error-detail">
                    <p class="mt-2 mb-2">
                      We apologize for the inconvenience. Please contact FIS
                      Ethos
                      <sup><small>TM</small></sup> Support for assistance at
                      1-844-634-7669 or email
                      <a href="mailto:ethos.support@fisglobal.com"
                        >ethos.support@fisglobal.com</a
                      >.
                    </p>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
      <template #overlay>
        <div class="text-center mt-5 pt-5">
          <h5 :style="{ color: 'white' }">Redirecting...Please wait...</h5>
        </div>
      </template>
    </b-overlay>
  </div>
</template>
<script charset="utf-8">
// global
import { mapState, mapGetters, mapActions, mapMutations } from 'vuex'
// api
import sessionAPI from '@/api/finapps/session'

export default {
  name: 'SSO',
  created() {
    if (this.$route.query.timeout) {
      this.sessionTimeout = true
      this.logOut({ skipRedirect: true, silent: true })
      this.resetEthosData()
    } else if (this.$route.query.invalid) {
      this.ssoError = true
      this.resetEthosData()
    } else if (this.authorizedUser) {
      this.setDocumentBodyClass()
      this.$router.push('/')
    } else {
      this.showOverlay = true
      this.fetchSessionUserData()
    }
  },
  data() {
    return {
      ssoError: false,
      showOverlay: false,
      sessionTimeout: false,
      pageHeight: window.innerHeight + 'px'
    }
  },
  computed: {
    ...mapState('Ethos', {
      redirectURL: state => state.redirectURL
    }),
    ...mapGetters('Authentication', ['authorizedUser'])
  },
  methods: {
    ...mapActions('Authentication', ['logOut']),
    ...mapActions('ThirdPartyServices', ['setUserDataToChameleon']),
    ...mapMutations(['setFinancialInstitutionBoolean']),
    ...mapMutations('Ability', [
      'setUserRole',
      'setUserAbility',
      'setEnabledModules',
      'setEnabledPaths',
      'setEnterprisePermissions',
      'setActiveClientPricingAvailable'
    ]),
    ...mapMutations('Authentication', ['setUserData']),
    ...mapMutations('Ethos', ['setRedirectURL', 'resetEthosState']),
    resetEthosData() {
      this.resetEthosState()
      this.removeDocumentBodyClass()
    },
    fetchSessionUserData() {
      sessionAPI.userDetails().then(res => {
        this.setUserData(res)
        this.setFinancialInstitutionBoolean()
        this.setUserRole(res.role.name)
        this.setUserAbility()
        this.setEnabledModules(res.ui_modules)
        this.setEnabledPaths(res.ui_modules)
        this.setActiveClientPricingAvailable(res.active_client_pricing)
        this.setEnterprisePermissions(res.enterprise_permissions)
        this.setUserDataToChameleon()
        this.setDocumentBodyClass()

        this.$ahoy.track('login', {
          user_id: res.id
        })

        if (this.redirectURL) {
          this.$router.push(this.redirectURL)
          this.setRedirectURL(null)
        } else {
          this.$router.push('/analytics/analytics/about')
        }
      })
    },
    setDocumentBodyClass() {
      if (this.authorizedUser) {
        document.body.classList.remove('non-actionable')
        document.body.classList.add('actionable')
      }
    },
    removeDocumentBodyClass() {
      document.body.classList.add('non-actionable')
      document.body.classList.remove('actionable')
    }
  }
}
</script>

<style lang="scss" scoped></style>
